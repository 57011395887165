import request from 'oc-request';
export default new class ContactForm{

    constructor()
    {
        this.eventListeners();
    }

    eventListeners()
    {
        const _this = this;
        document.addEventListener('vue-mounted', function (v) {
            let contactForm = document.querySelector(`form[name='contact-form']`);
            let consultaForm = document.querySelector(`form[name='consulta-form']`);
            let fichaNuevoForm = document.querySelector(`form[name='ficha-nuevo-form']`);
            let fichaNuevoFormSlider = document.querySelector(`form[name='ficha-nuevo-form-slider']`);
            let consultaNuevoForm = document.querySelector(`form[name='consulta-nuevo-form']`);

            if (contactForm) {
                contactForm.addEventListener(
                    'submit',
                    function (e) {
                        contactForm.classList.add('was-validated');
                        if (contactForm.checkValidity() === false) {
                            e.preventDefault();
                            e.stopPropagation();
                            _this.showErrors(contactForm);
                            _this.alertHandler('#errors');
                        } else {
                            e.preventDefault();
                            e.stopPropagation();
                            _this.loaderHandler(contactForm, '.loader-container');
                            request.sendForm(contactForm, 'contactoForm::onFormSubmit', {
                                flash: true,
                                success: () => {
                                    _this.alertHandler('#success');
                                    _this.loaderHandler(contactForm, '.loader-container');
                                    _this.resetForm(contactForm);
                                }
                            })
                        }

                    }
                )
            }

            if (consultaForm) {
                consultaForm.addEventListener(
                    'submit',
                    function (e) {
                        consultaForm.classList.add('was-validated');
                        if (consultaForm.checkValidity() === false) {
                            e.preventDefault();
                            e.stopPropagation();
                            _this.showErrors(consultaForm);
                            _this.alertHandler('#errors');
                        } else {
                            e.preventDefault();
                            e.stopPropagation();
                            _this.loaderHandler(consultaForm, '.loader-container');
                            request.sendForm(consultaForm, 'consultaForm::onFormSubmit', {
                                flash: true,
                                success: () => {
                                    _this.alertHandler('#success');
                                    _this.loaderHandler(consultaForm, '.loader-container');
                                    _this.resetForm(consultaForm);
                                }
                            })
                        }

                    }
                )
            }

            if (fichaNuevoForm) {
                fichaNuevoForm.addEventListener(
                    'submit',
                    function (e) {
                        fichaNuevoForm.classList.add('was-validated');
                        if (fichaNuevoForm.checkValidity() === false) {
                            e.preventDefault();
                            e.stopPropagation();
                            _this.showErrors(fichaNuevoForm);
                            _this.alertHandler('#errors');
                        } else {
                            e.preventDefault();
                            e.stopPropagation();
                            _this.loaderHandler(fichaNuevoForm, '.loader-container');
                            request.sendForm(fichaNuevoForm, 'nuevoFichaForm::onFormSubmit', {
                                flash: true,
                                success: () => {
                                    _this.alertHandler('#success');
                                    _this.loaderHandler(fichaNuevoForm, '.loader-container');
                                    _this.resetForm(fichaNuevoForm);
                                    // document.querySelector('#doc').click();
                                }
                            })
                        }

                    }
                )
            }

            if (fichaNuevoFormSlider) {
                fichaNuevoFormSlider.addEventListener(
                    'submit',
                    function (e) {
                        fichaNuevoFormSlider.classList.add('was-validated');
                        if (fichaNuevoFormSlider.checkValidity() === false) {
                            e.preventDefault();
                            e.stopPropagation();
                            _this.showErrors(fichaNuevoFormSlider);
                            _this.alertHandler('#errors');
                        } else {
                            e.preventDefault();
                            e.stopPropagation();
                            _this.loaderHandler(fichaNuevoFormSlider, '.loader-container');
                            request.sendForm(fichaNuevoFormSlider, 'nuevoFichaForm::onFormSubmit', {
                                flash: true,
                                success: () => {
                                    _this.alertHandler('#success');
                                    _this.loaderHandler(fichaNuevoFormSlider, '.loader-container');
                                    _this.resetForm(fichaNuevoFormSlider);
                                    // document.querySelector('#doc').click();
                                }
                            })
                        }

                    }
                )
            }

            if (consultaNuevoForm) {
                consultaNuevoForm.addEventListener(
                    'submit',
                    function (e) {
                        consultaNuevoForm.classList.add('was-validated');
                        if (consultaNuevoForm.checkValidity() === false) {
                            e.preventDefault();
                            e.stopPropagation();
                            _this.showErrors(consultaNuevoForm);
                            _this.alertHandler('#errors');
                        } else {
                            e.preventDefault();
                            e.stopPropagation();
                            _this.loaderHandler(consultaNuevoForm, '.loader-container');
                            request.sendForm(consultaNuevoForm, 'nuevoConsultaForm::onFormSubmit', {
                                flash: true,
                                success: () => {
                                    _this.alertHandler('#success');
                                    _this.loaderHandler(consultaNuevoForm, '.loader-container');
                                    _this.resetForm(consultaNuevoForm);
                                }
                            })
                        }

                    }
                )
            }

        })
    }

    showErrors(form)
    {
        let errorMessages = form.querySelectorAll('small.text-danger');
        errorMessages.forEach((el) => {
            el.classList.remove('d-none');
        })
    }

    hideErrors(form)
    {
        let errorMessages = form.querySelectorAll('small.text-danger');
        errorMessages.forEach((el) => {
            el.classList.add('d-none');
        })
    }

    alertHandler(selector)
    {
        setTimeout(() => {
            let alert = document.querySelector(`${selector}`);
            alert.classList.add('show');
            setTimeout(() => {
                alert.classList.remove('show');
            }, 5000)
        }, 300)
    }

    loaderHandler(form, selector)
    {
        let loader = form.querySelector(`${selector}`);
        if (loader.classList.contains('is-active')) {
            loader.classList.remove('is-active');
        } else {
            loader.classList.add('is-active');
        }
    }

    resetForm(form)
    {
        form.classList.remove('was-validated');
        let fields = form.querySelectorAll('input[name], textarea[name]');

        fields.forEach((el) => {
            el.value = '';
        })
    }

}();
