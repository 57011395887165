import CatalogProductList from '../product-list/product-list';
// import PromoBlockProductList from './../promo-block-product-list/promo-block-product-list';
// import PromoBlockList from './../../promo-block/promo-block-list/promo-block-list';

export default new class ProductSorting {
    constructor()
    {
        this.sortingSelectSelector = 'sorting__select';

        this.catalogProductListLabel = 'catalog-product-list';
        this.promoBlockListLabel = 'promo-block-list';
        this.promoBlockProductListLabel = 'promo-block-product-list';
        this.myFilter = 'filter_car';

        this.eventHandlers();
    }
    

    eventHandlers()
    {
        const _this = this;
        if (!document.querySelectorAll(`.${this.sortingSelectSelector} > option`).length) {
            return;
        }

        document.addEventListener(
            'change',
            (e) => {
                const select = e.target.closest(`.${_this.myFilter}`);
               
                var labels = document.querySelectorAll("label");
                var i;
                for (i = 0; i < labels.length; i++) {
                    if (labels[i].firstChild.checked) {
                        labels[i].className = "option-filter";
                    }else {
                        if (labels[i].className !== 'filter-title') {
                            labels[i].className = "option-filter2";
                        }                        
                    }
                    
                }

                //e.target.parentNode.className = "option-filter";

                if (select) {
                    const label = select.getAttribute('aria-label');


                    switch (label) {
                        case this.catalogProductListLabel:
                        CatalogProductList.updateProductList();
                        break;
                    }
                }
            }
        );
    }
}();

