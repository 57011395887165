//Libraries
window.LazyLoad = require('vanilla-lazyload');
window.Gallery = require('flickity-imagesloaded');
window.axios = require("axios");
window.oc = require('oc-request');
window.AOS = require('aos')
window.BSN = require('bootstrap.native/dist/bootstrap-native-v4.min')
window.windowScrollTo = require('scroll-to-element');
window.Fullpage = require('fullpage.js');
// window.SlimSelect = require('slim-select');
require('flickity');
require('flickity-fade');
require("flickity-fullscreen");
require("flickity-as-nav-for");
require("flickity-bg-lazyload");
require('./global-functions/functions');
require('./components/dot-navigation/dot-nav');

//Components
require('./components/mainslider/main-slider')
require('./components/contactForm/contactForm');
require('./components/product-sorting/product-sorting');
require('./components/pagination/_pagination');
